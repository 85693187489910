$theme-colors: (
        "primary": #7B57DF,
        "secondary":  #1B2149,
        "success":    #198754FF,
        "info":       #0DCAF0FF,
        "warning":    #FFC107FF,
        "danger":     #DC3545FF,
        "light":      #fff,
        "dark":       #1B2149,
);
.form-check-input[type=checkbox] {
  background-color: map-get($theme-colors, "light");
  border-color: map-get($theme-colors, "primary");

  &:checked {
    background-color: map-get($theme-colors, "primary");
    border-color: map-get($theme-colors, "primary");
  }
  &:indeterminate{
    background-color: map-get($theme-colors, "primary") !important;
    border-color: map-get($theme-colors, "primary");
  }
}

.custom-select .custom-select-option:hover {
    color: map-get($theme-colors, "primary");
  }
.dropdown-menu .dropdown-item:active {
  background-color: map-get($theme-colors, "primary") !important; /* Different background for active items */
  color: #fff;
}

.progress-bar {
  background-color: map-get($theme-colors, "primary") !important;
}

@import '~bootstrap/scss/bootstrap.scss';
