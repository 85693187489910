.scan-page {
  overflow: hidden;
}

.qr-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
  margin-bottom: 30px;
}

.custom-list-item {
  padding: 0 !important;
  margin-bottom: 9px;
}

.steps {
  padding-top: 7px;
  :global(.ant-list-item) {
    display: block !important;
  }
}

.manual-set {
  display: flex;
}

.search-courier {
  width: 255px;
  margin-bottom: 10px;
}

.choose-date {
  width: 240px;
  white-space: nowrap;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.date-picker {
  :global(.ant-picker-input) {
    input {
      font-size: 16px !important;
    }
  }
}

.table-section {
  :global(.ant-table-container) {
    height: 430px;
    overflow: auto;

  }
}

.modal {
  :global(.ant-modal-content) {
    padding: 30px !important;
  }
}

.print {
  cursor: pointer;
  width: 200px;
  margin-left: auto !important;

  &:hover {
    .printText {
      color: var(--accent-main) !important;
    }

    .printIcon {
      color: var(--accent-main) !important;
      fill: var(--accent-main) !important;
    }
  }
}

.printText {
  color: var(--content-dop) !important;
  margin-left: 8px !important;
  transition: all 0.3s ease;
}

.printIcon {
  color: var(--content-dop) !important;
  fill: var(--content-dop) !important;
  transition: all 0.3s ease;

}

.ok-button {
  padding: 17px 66px !important;
}

.custom-scan-table-wrapper {
  box-sizing: content-box;
  width: 100%;
  //height: 80dvh !important;
  //overflow: auto !important;
  scrollbar-width: auto;
  scrollbar-color: auto;


  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
    height: 18% !important;
    color: var(--accent-main-light) !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-main-light) !important; /* Background of the scrollbar track */
    border-radius: 2px;
  }
}

.custom-scan-table {
  display: table !important;
  width: 100%;
  box-sizing: content-box;

  & > thead, & > tbody{
    width: 100% !important;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      box-sizing: border-box !important;
      padding: 12px 8px !important;
      line-height: 5px !important;
      max-width: 280px !important;
      width: auto !important;
    }
  }
}