.scan-page {
  overflow: hidden;
}

.qr-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
  margin-bottom: 30px;
}

.custom-list-item {
  padding: 0 !important;
  margin-bottom: 9px;
}

.steps {
  padding-top: 7px;
  :global(.ant-list-item) {
    display: block !important;
  }
}

.manual-set {
  display: flex;
}

.search-cell {
  width: 255px;
  margin-bottom: 10px;
}


.table-section {
  :global(.ant-table-container) {
    height: 430px;
    overflow: auto;

  }
}

.modal {
  :global(.ant-modal-content) {
    padding: 30px !important;
  }
}






.ok-button {
  padding: 17px 66px !important;
}

.custom-scan-table-wrapper {
  box-sizing: content-box;
  width: 100%;
  //height: 80dvh !important;
  //overflow: auto !important;
  scrollbar-width: auto;
  scrollbar-color: auto;


  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
    height: 18% !important;
    color: var(--accent-main-light) !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-main-light) !important; /* Background of the scrollbar track */
    border-radius: 2px;
  }
}

.custom-scan-table {
  display: table !important;
  width: 100%;
  box-sizing: content-box;

  & > thead, & > tbody{
    width: 100% !important;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      box-sizing: border-box !important;
      padding: 12px 8px !important;
      line-height: 5px !important;
      max-width: 280px !important;
      width: auto !important;
    }
  }
}


  .modal-table-wrapper {
    max-height: 530px !important;
    overflow-y: auto !important;
    scrollbar-width: auto;
    scrollbar-color: auto;

      &::-webkit-scrollbar {
        width: 8px !important;
        color: var(--accent-main-light) !important;
      }

      &::-webkit-scrollbar-thumb {
        width: 8px !important;
        height: 8px !important;
        color: var(--accent-main-light) !important;
        background-color: var(--accent-main-light) !important; /* Background of the scrollbar track */
        border-radius: 2px;
      }
  }
