

.pagination {
  .page-item {

    &.active {
      .page-link {
        color: var(--bs-primary);
        background-color: var(--bs-light);
        border: 2px solid var(--bs-primary);
      }
    }

    .page-link {
      border-radius: 10px;
      border: 0 solid var(--bs-primary);
      color: var(--bs-primary);
    }
  }

  .chevron {
    background-color: var(--main-background);
  }

}