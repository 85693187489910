.custom-ant-heading-wrapper{
  --heading-color: var(--content-main);
  --heading-size: 48px;
  --heading-weight: 600;
  --heading-line-height: 50px;

  .custom-ant-heading {
    color: var(--heading-color);
    font-size: var(--heading-size);
    font-weight: var(--heading-weight);
    line-height: var(--heading-line-height);

    margin: 0;
    padding: 0;
  }

  .custom-h1-heading {
    --heading-size: 48px;
    --heading-weight: 600;
    --heading-line-height: 50px;
  }
  .custom-h2-heading {
    --heading-size: 32px;
    --heading-weight: 600;
    --heading-line-height: 34px;
  }
  .custom-h3-heading {
    --heading-size: 24px;
    --heading-weight: 500;
    --heading-line-height: 26px;
  }

}