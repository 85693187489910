:root {

  // Bootstrap customization
  --background: #9c88ff;
  --main-background: #F9F9FC;
  --navbar-width: 180px;
  --navbar-dark-primary: #ffffff;
  --navbar-arrow-background: #B3A1E5;
  --navbar-dark-secondary: #2c3e50;
  --navbar-light-primary: #7B57DF;
  --navbar-light-secondary: #969AB4;
  --navbar-light-secondary-rgb: 150, 154, 180, 0.4;


  // Mercurio UI Kit:
  // Colors:
  --content-main: #1B2149;
  --content-drop: #969AB4;
  --content-dop: #969AB4;
  --content-dop-light: #C3C8E5;
  --content-dop-dark: #4F567E;
  --accent-main: #7B57DF;
  --accent-main-light: #B3A1E5;
  --accent-green: #5DC983;
  --accent-green-light: #E9F7EE;
  --accent-red: #EB5769;
  --accent-red-light: #FDEEF0;
  --accent-orange: #F2AF4C;
  --accent-orange-light: #FCF3E6;
  --button-hover: #9576EB;
  --button-active: #5E3AC2;

}

#layout {
  width: 75vw;
  flex-grow: 1;
  height: 100dvh;
  background-color: var(--main-background);

  padding: 0 2rem;
  margin: 0;

  & > div {
    height: 100%;
  }
}

#nav-toggle:checked {
  margin-left: 0 !important;
  margin-right: 0 !important;

  & ~ #nav-header {
    width: 100% !important;
    padding-left: 24px !important;

    #nav-title {
      opacity: 0;
      pointer-events: none;
      transition: all 1s;
      display: none;
    }

    label[for="nav-toggle"] {
      transform: translate(-50%);
      right: auto;
      margin-left: 0;
    }

    hr{
      border: none;
      transition: border 3s ease;
    }

    #nav-toggle-burger {
      position: relative;
      width: 30px;
      height: 30px;
      right: 65px;
      background: rgba(var(--navbar-light-secondary-rgb));

      &:before, &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 2px;
        background: var(--navbar-light-secondary);
      }
      &:before, &:after {
        background: var(--navbar-light-primary);
      }
      &:before {
        top: 10px;
        left: 10px;
        transform: rotate(45deg);
      }
      &:after {
        top: 16px;
        left: 10px;

        transform:  rotate(-45deg);
      }

    }
  }

  & ~ #nav-content, & ~ #nav-footer {
    padding-right: 0 !important;
  }

  & ~ #nav-footer {
    margin-bottom: 54px;
    width: 100%;
  }

  & ~ #nav-content, & ~ #nav-footer {
    .nav-text {
      opacity: 0;

    }

    .nav-button {
      .active {
        //border-left: 4px solid #7B57DF !important;
        color: white;
      }
      span {
        opacity: 0;
        transition: all 1s;
      }

      .fas {
        min-width: calc(100% - 10px);
        margin-left: 5px;

      }

      #logout {
        margin-left: 0;
      }
    }
  }
  & ~ #nav-footer {
    .nav-button {
      justify-content: space-between !important;
      margin-bottom: 10px;

      .btn {
        padding: 0.45rem;
      }
      a {
        padding: 0 !important;

        .d-flex {
          padding: 0 !important;
        }
      }

    }
  }


}

#nav-bar {
  position: sticky;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  width: 18.125vw;
  height: 100dvh;
  background: var(--navbar-dark-primary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  color: var(--navbar-light-primary);
  user-select: none;
  transition: width 0.3s ease;

  hr {
    margin: 0;
    position: relative;
    width: 100%;
    margin-left: -24px;
    border: none;
    border-top: solid 1px var(--navbar-dark-secondary);
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  input[type="checkbox"] {
    display: none;
  }
}

#nav-header {
  position: relative;
  padding: 1.25rem 0;
  padding-left: 24px !important;
  width: 100%;
  min-height: 50px;
  background: var(--navbar-dark-primary);
  border-radius: 16px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 1s;
  text-align: start;

  hr {
    position: absolute;
    bottom: 0;
  }
}

.logo {
  display: flex;
  width: 100% !important;

  .logo-img {
    width: 35px;
  }
  .logo-body {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    .textUp {
      width: 54px;
    }
    .textDown {
      width: 80px;
      margin-top: -3px;
    }
  }
}

#nav-title {
  font-size: 1.5rem;
  color: var(--navbar-light-secondary);
  opacity: 1;
  transition: all 1s;
  text-align: end;
  font-weight: 500;
  letter-spacing: 0;
}

label[for="nav-toggle"] {
  position: relative;
  width: 3rem;
  height: 100%;
  align-self: flex-end;
  margin-left: auto;
  margin-right: -15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

#nav-toggle-burger {
  position: relative;
  width: 30px;
  height: 30px;
  right: 0;
  background: rgba(var(--navbar-light-secondary-rgb));
  border-radius: 99px;
  transition: background .2s;


  &:before, &:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 7px;
    width: 10px;
    height: 2px;
    transform: translate(2px, 8px) rotate(30deg);
    background: var(--navbar-light-primary);
    border-radius: 99px;
    transition: .2s;
  }

  &:after {
    top: 21px;
    left: 7px;
    transform: translate(2px, -8px) rotate(-30deg);
  }
}

#nav-content {
  margin: -16px 0;
  padding: 16px 0;
  padding-right: 16px;
  height: 70%;
  position: relative;
  width: 100% !important;
  background: var(--navbar-dark-primary);
  direction: rtl;
  overflow-x: hidden;
  transition: width .2s;
  text-align: left;


  .nav-text {
    display: inline-block;
    line-height: 10px !important;
    margin-left: 34px !important;
    margin-top: 24px !important;
    margin-bottom: 8px !important;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #c9c9c9;
  }

  &::-webkit-scrollbar-button {
    height: 16px;
  }
}



hr {
  position: relative;
}

#nav-content-highlight {
  position: absolute;
  top: calc(-54px - 16px);
  width: calc(100% - 16px);
  height: 54px;
  background: var(--background);
  background-attachment: fixed;
  border-radius: 16px 0 0 16px;
  transition: top .2s;

  &:before, &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 16px 16px var(--background);
  }

  &:after {
    top: 100%;
    box-shadow: 16px -16px var(--background);
  }
}

.nav-button {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--navbar-light-secondary);
  direction: ltr;
  cursor: pointer;
  z-index: 1;
  transition: none !important;
  span {
    transition: opacity 1s;
  }
  &-active{
    border-left: 4px solid #7B57DF;
  }

  .fas {
    transition: min-width .2s;
    height: 25px;
  }

  & .active {
    & .d-flex {
      .fas {
        color: #7B57DF;
      }
    }
  }

}

#nav-bar .fas {
  min-width: 3rem;
  text-align: center;
  margin-left: -10px;
}


#nav-footer {
  position: relative;
  margin-top: auto;
  margin-bottom: 30px;
  width: 100%;
  padding-right: 16px !important;
  padding-left: 15px !important;
  height: 54px;
  background: var(--navbar-dark-primary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: width .2s, height .2s;
}

#nav-footer-heading {
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
}

#nav-toggle:not(:checked) ~ #nav-footer-toggle:checked + #nav-footer {
  height: 30%;
  min-height: 54px;

  label[for="nav-footer-toggle"] {
    transform: rotate(180deg);
  }
}

label[for="nav-footer-toggle"] {
  position: absolute;
  right: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 1s;
}

.user-card {
  width: 50%;
}

.logout {
  width: 50px;
  height: 50px;
  padding: 0;
  position: relative;
  right: 0;
}

.user-card-dropdown {

  ul {
    li{
      align-items: center !important;
      width: 148px !important;
      height: 32px !important;
      text-align: center !important;

      svg {
        transition: all 0.2s ease;
        color: var(--content-dop) !important;
      }
      span {
        line-height: 16px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        transition: all 0.2s ease;
        color: var(--content-dop) !important;
      }

      &:hover {
        background-color: #F0EEFA !important;
        svg {
          color: var(--accent-main) !important;
        }
        span {
          color: var(--accent-main) !important;
        }
      }
    }
  }
}