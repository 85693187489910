.border-none {
    border: none;
}

.border-dotted {
    border: dotted;
    border-width: 3px;
    border-color: lightgray;
}

.border-rounded-1 {
    border-radius: 0.6rem;
}

.border-rounded-2 {
    border-radius: 0.9rem;
}
