.custom-client-container {
  padding: 0;
}

:global(.ant-pagination) {
  font-size: 14px !important;

  :global(.ant-pagination-item-active) {
    color: var(--accent-main);
    border-color: var(--accent-main);
  }

  :global(.ant-pagination-item) {
    font-weight: 500;

    &:hover {
      background-color: #fff;
      color: var(--accent-main);
    }
  }

  :global(.ant-select-selector) {
    border: 1px solid #F0EEFA;
  }

  :global(.ant-pagination-options) {

    :global(.ant-select) {
      :global(.ant-select-selector) {
        font-size: 14px !important;
      }
    }

  }

  :global(.ant-pagination-options-quick-jumper) {
    font-size: 14px;
    input {
      border: 1px solid #F0EEFA;
    }
  }
}

.custom-client-table-wrapper {
  box-sizing: content-box;
  width: 100%;
  height: 80dvh !important;
  overflow: auto !important;
  scrollbar-width: auto;
  scrollbar-color: auto;


  &::-webkit-scrollbar {
    width: 8px !important; /* Width of the vertical scrollbar */
    height: 18% !important;
    color: var(--accent-main-light) !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-main-light) !important; /* Background of the scrollbar track */
    border-radius: 2px;
  }
}

.custom-client-table {
  display: table !important;
  width: 100%;
  box-sizing: content-box;

  & > thead, & > tbody{
    width: 100% !important;
  }
}

.client-table-td {
  padding: 0 7px 3px 9px !important;
  word-break: break-word;

  & > :global(.ant-table-column-sorters) > :global(.ant-table-column-sorter) {
    margin: 0 !important;
  }
}