.custom-ant-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  border-color: var(--content-dop-light);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--content-main) !important;

  &:global(.ant-picker-focused) {
    box-shadow: 0 0 0 2px var(--accent-main-light) !important;
  }

  &:hover {
    border-color: var(--accent-main) !important;
  }

  &::placeholder {
    color: var(--content-dop-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }


  &:global(.ant-picker-status-error) {
    border-color: var(--accent-red) !important;
  }

  &:global(.ant-input-affix-wrapper-focused) {
    box-shadow: 0 0 0 2px var(--accent-main-light) !important;

    &:global(.ant-input-status-error) {
      border-color: var(--accent-red) !important;
      box-shadow: 0 0 0 2px #F2A8B2 !important;
    }
  }


  input {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px !important;
    color: var(--content-main) !important;
    line-height: normal !important;
    border: 1px solid var(--content-dop-light);

    &::placeholder {
      color: var(--content-dop-light) !important;
    }
  }
}
