.custom-ant-button-wrapper {
  --text-color: white;
  --default: var(--accent-main);
  --hover: #9576EB;
  --active: #5E3AC2;
  --focus-shadow: 0 0 0 2px var(--accent-main-light);

  .custom-ant-button {
    padding: 22px 50px;
    background-color: var(--default) !important;
    border: none;
    border-radius: 4px;

    &:hover {
      background-color: var(--hover) !important;
    }

    &:active {
      box-shadow: none;
      background: var(--active) !important;
    }

    &:focus {
      background-color: var(--default) !important;
      box-shadow: var(--focus-shadow) !important;
    }

    span {
      color: var(--text-color) !important;
      font-size: 16px;
      font-weight: 600 !important;
      line-height: 19px;
    }

    :global(.ant-btn-loading-icon) {
      display: none !important;
    }
  }

  :global(button.ant-btn-loading) {
    background-color: var(--active) !important;
    pointer-events: none !important;
    box-shadow: none !important;
    opacity: 1;

    &:focus {
      box-shadow: none !important;
    }
  }




  .custom-default-ant-button {
    --text-color: var(--accent-main);
    --default: #F0EEFA;
    --hover: #F7F5FF;
    --active: #D5CFF2;
    --focus-shadow: 0 0 0 2px var(--accent-main-light);
  }

  .custom-error-ant-button {
    --text-color: white;
    --default: var(--accent-red);
    --hover: #EB7684;
    --active: #DB4254;
    --focus-shadow: 0 0 0 2px #F5C9D0;
  }
}

.custom-small-ant-button {
  padding: 15px 26px
}

