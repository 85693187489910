.custom-ant-input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  border-color: var(--content-dop-light);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--content-main) !important;

  input:-webkit-autofill,
  input:-webkit-autofill::first-line,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--content-main) !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    font-family: 'Gilroy', sans-serif !important;
  }

  &:focus, &:hover {
    border: 1px solid var(--accent-main) !important;
  }

  &::placeholder {
    color: var(--content-dop-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &:global(.ant-input-status-error) {
    border-color: var(--accent-red) !important;
  }

  &:global(.ant-input-affix-wrapper-focused) {
    box-shadow: 0 0 0 2px var(--accent-main-light) !important;

    &:global(.ant-input-status-error) {
      border-color: var(--accent-red) !important;
      box-shadow: 0 0 0 2px #F2A8B2 !important;
    }
  }


  input {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid var(--content-dop-light);
  }
}
