.attach {
  margin-top: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  width: 100%;
  height: 100dvh;

  .attach-title {
    margin-top: 1.25rem;
  }

  .attach-spinner {
    margin-top: 30dvh;
    font-size: 96px;
  }

  .toolbar-wrapper {
    transform: scale(2);
  }

  .attach-card {
    position: relative;
    margin: 0.5rem 0.25rem;
    border-radius: 20px;

    .ant-card-body {
      border: 2px solid #c2c2c2;
      border-radius: 20px;
      padding: 0.5rem;
      background-color: #f0f0f0;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.trash-container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  padding: 5px;
  border-radius: 0.5rem;
  background-color: red;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trash-icon {
  font-size: 20px;
  color: white;
}