.sidebar {
    height: 90vh;
}

@media (max-width: 991px) {
    .sidebar {
        height: 100%;
    }
}

.cursor {
    cursor: pointer;
}

.text-decor-none {
    text-decoration: none;
}

.wide-modal {
	width: 90vw !important;
    min-width: 90vw !important;
}
