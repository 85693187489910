.orders-import {

}

.import-dropdown {
  margin: 0.5rem 0;
    .ant-select-selection-search-input {
      font-size: 0.8rem;
  }
  .ant-select-selection-placeholder {
    .ant-typography {
      font-size: 0.8rem;
    }
  }

}

#import-first-step-modal {

}

#first-step-footer {
  display: flex;
  margin-top: 5rem;
  justify-content: flex-end;

  .footer-button {
    margin-top: 0.5rem;
    padding: 1rem 2.25rem;
    font-weight: 500;
  }
}

.import-second-step {
  .second-step-title {
    font-size: 1rem !important;
  }
}

#second-step-footer {
  display: flex;
  justify-content: space-between;

  .footer-button {
    margin-top: 0.5rem;
    padding: 1rem 2.25rem;
    font-weight: 500;
  }
}

#second-step-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .file-body {

    .import-file-name {
      font-weight: 600;
      font-size: 1rem;
    }

    .import-file-size {
      font-size: 0.75rem;
    }
  }

  .import-file-status {
    margin-left: auto;
  }

  .file-img {
    width: 1.75rem;
    margin-right: 0.5rem;
  }
}

.edit-img {
  width: 1.25rem;
  margin-right: 0.5rem;
  cursor: pointer;

  &.error {
    cursor: not-allowed;
  }
}

.import-dragger {
  .dragger-text {
    font-size: 1rem;
  }

  .dragger-hint {
    font-size: 0.8rem !important;
  }

  .ant-upload {
    margin: 1rem 0 !important;
  }
}

.import-error {
  display: flex;
  justify-content: space-between;

  .import-error-body {
    width: 95%;

    .error-text {
      margin-bottom: 0;
      font-size: 0.8rem;
    }
  }
}

.import-row-error {
  background-color: #ffdbdb; // Red background
  border-color: #ffdbdb !important;

  .ant-table-cell-row-hover {
    background-color: #ffb2bd !important; // Red background
    border-color: #ffb2bd !important; // Red background
  }
}

.ant-table-row{
  font-size: 0.875rem !important;
  &.import-row-fixed {
    background-color: #C7BEEE;
    border-bottom: 2px solid inherit;

    .ant-table-cell.ant-table-cell-row-hover {
      background-color: #D5CFF2 !important;
      border-color: #D5CFF2 !important;
    }
  }
  .ant-table-cell {
    padding: 0.4rem !important;
    border: 0;
  }
  &.import-row-error {
    .ant-table-cell.ant-table-cell-row-hover {
      background-color: #FDEEF0 !important;
      border-color: #FDEEF0 !important;
    }
  }

  .ant-table-cell.ant-table-cell-row-hover {
    background-color: #F0EEFA !important;
    border-color: #F0EEFA !important;
  }


  .import-cell {
    color: #1B2149;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.ant-form-item-explain-error{
  display: inline;
}

.choose-date-form {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.choose-date {
  width: 240px;
  white-space: nowrap;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.date-picker {
  :global(.ant-picker-input) {
    input {
      font-size: 16px !important;
    }
  }
}

