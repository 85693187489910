.login-container {
  position: relative;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login-background-image {
  position: relative;
  z-index: 4;
  width: 53vw;
  height: 76.6dvh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 14.2dvh;
  margin-left: 1.6vw;
}

.login-divider {
  position: absolute;
  content: '';
  z-index: 1;
  width: 35vw;
  background-color: #F0EEFA;
  top: 0;
  left: 0;
  bottom: 0;
}

.login-form {
  width: 370px;
  padding-left: 0.55dvh;
  padding-bottom: 2.8dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-header {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.login-enter-data {
  margin-top: 20px !important;
  padding-bottom: 35px !important;
  margin-bottom: 0 !important;
}

.auth-login {
  padding-bottom: 18px !important;
  margin-bottom: 0 !important;
}

.auth-password {
  padding-bottom: 28px !important;
  width: 82% !important;
  margin-bottom: 0 !important;
}

.auth-password, .auth-login {
  width: 300px !important;

  :global(.ant-form-item-label) {
    padding: 0 !important;
    margin: 0 !important;
    line-height: normal;
  }
}
