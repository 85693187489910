@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-BoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-Bold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-RegularItalic.ttf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-MediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/src/assets/fonts/Gilroy-SemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: 'Gilroy', sans-serif !important;

}

.btn-text {
  font-family: 'Gilroy', sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.heading-1 {
  font-family: 'Gilroy', sans-serif;
  font-size: 2.35rem;
  font-weight: 600;
}

.heading-2 {
  font-family: 'Gilroy', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.heading-3 {
  font-family: 'Gilroy', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.heading-4 {
  font-family: 'Gilroy', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
}

.heading-sm {
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
}

.heading-font {
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
}

.heading-badge {
  font-family: 'Gilroy', sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
}