body {
    font-family: 'Gilroy', sans-serif;
}

.brand {
    width: auto;
    height: 25px;
}

.note-content {
    position: relative;
    padding-bottom: 17px;
    width: 690px;
    height: 978px;
    border-color: black;
}

.dparty-body {
    padding-top: 13px;
}

.order-body {
    margin-bottom: 20px;
}

.signature-body {
    margin-bottom: 11px;
}

.qr-body {
    margin-bottom: 4px;
}

.delimiter {
    border-bottom: dashed 2px black;
    width: calc(100% + 46px);
    margin-left: -23px;
    margin-bottom: 10px;
}

.header-1 {
    font-size: 18px;
    font-weight: 700;
}

.header-2 {
    font-size: 16px;
    font-weight: 600;
    padding-right: 4px;
}

.header-3 {
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
}

.header-4 {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
}

.font-property {
    font-size: 14px;
    font-weight: 400;
}

.underscore-fill {
    margin-bottom: 4px;
    flex-grow: 1;
    border-bottom: 2px solid black;
    position: relative;
    height: 1px;
}

.underscore-fill::before {
    content: "";
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}
