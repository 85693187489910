.formik-input {
  display: block;
  flex-grow: 1;
  height: 100%;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 0.4rem 1rem;
  margin: 0.25rem 0;
  line-height: 1.2;
}

.formik-input:focus,
.formik-input:active {
  outline: none;
}

.formik-input:hover {
  display: block;
  flex-grow: 1;
  height: 100%;
  border-radius: 0.5rem;
  border: 0.5px solid #c9c9c9;
  padding: 0.4rem 1rem;
  margin: 0.25rem 0;
  line-height: 1.2;
  background-color: #f5f5f5;
}


.formik-error {
  color: red;
}

.formik-input, .formik-label, .formik-error {
  font-size: 1rem;
}