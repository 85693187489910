.body-sticker {
    width: 70mm;
    height: 100mm;
    padding: 15px;
}

.font-sticker {
    font-family: 'Arial';
    font-weight: 600;
    font-size: 12px;
    line-height: 9.68px;
}

.delivery-party-info {
    margin-top: 14px;
    line-height: 14px;
}

.field {
    margin-top: 10px;
}

.font-sticker-header {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 10px;
}

.field-section {
    width: 110px;
    word-wrap: break-word;
}

.delivery-field-section {
    margin-top: 5px;
}

.space-right {
    margin-right: 15px;
}

.font-sticker-route {
    font-weight: 500;
    font-size: 25px;
}

.font-bold {
    font-weight: 700;
}

.border-sticker {
    border: solid 3px black;
}