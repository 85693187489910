.camera-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.7);
}

.camera-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1010;
}

.flash-btn {
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 0.3s, color 0.3s;
}

.flash-btn.active {
    background-color: yellow;
    color: black;
}

.camera-preview-bar {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: start;
    position: fixed;
    bottom: 50px;
    left: 0;
    z-index: 1010;
    overflow-x: auto;
}

.camera-control-bar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1010;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0 20px;
}

.camera-shoot-btn {
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    outline: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s, transform 0.1s;
}

.camera-shoot-btn:active {
    background-color: #d4d4d4;
    transform: scale(0.95);
}

.camera-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 20px;
    color: white;
}
